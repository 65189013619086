<template>
  <div class="home">
    <header>
      <van-row class="hdBox" type="flex" justify="space-between" align="center">
        <van-col span="8" class="logoBox">
          <img :src="require('../assets/logo.png')" />
        </van-col>
        <van-col span="8" class="navBox">
          <van-row type="flex" justify="space-between" align="center">
            <!-- <van-col span="4">Faceswap</van-col>
						<van-col span="4">定价</van-col> -->
            <!-- <van-col span="4">Assets</van-col> -->
            <!-- <van-col span="4">Results</van-col> -->
            <!-- <van-col span="6">Refer a Friend</van-col> -->
          </van-row>
        </van-col>
        <!-- <van-col span="8">
					<van-row type="flex" justify="space-between" class="userBox" align="center">
						<van-col span="12" class="languageCol">
							<div class="language">
								<img :src="require('../assets/language.png')" alt="" />
								<span>语言</span>
							</div>
							<div class="menu">
								<span class="iconfont icon-caidan"></span>
							</div>
						</van-col>
						<van-col span="12">
							<div class="userLink">
								<img :src="require('../assets/header.png')" alt="" />
								<div class="info">
									<div class="user_name">user</div>
									<div class="user_level">My Credits:2</div>
								</div>
							</div>
						</van-col>
					</van-row>
				</van-col> -->
      </van-row>
    </header>
    <div class="publicity_box">
      <video ref="video_ref" autoplay muted loop>
        <source src="../static/media/home10.mp4" type="video/mp4" />
        您的浏览器不支持 video 标签。
      </video>
      <van-icon
        v-show="isVideoPlay"
        @click="handlePause"
        size="1.875rem"
        class="pause video_icon"
        name="pause-circle-o"
      />
      <van-icon
        :style="{
          opacity: !isVideoPlay ? '1' : '0',
          zIndex: !isVideoPlay ? '999' : '-1',
        }"
        @click="handlePlay"
        size="1.875rem"
        class="play video_icon"
        name="play-circle-o"
      />
    </div>
    <div>
      <div class="faceswap_box">
        <div class="faceswap">
          <h3>您可以在全球范围内轻松传播您的换脸作品！</h3>
          <img :src="require('../assets/1.png')" alt="" />
        </div>
      </div>
      <div class="faceswap_box wefaceswap_box">
        <div class="faceswap">
          <div class="img_box">
            <ImageComparison
              class="main_left"
              style="width: 37.5rem"
              value="45"
            >
              <img
                slot="first"
                style="
                  object-fit: cover;
                  height: 100%;
                  width: 100%;
                  display: block;
                "
                :src="require('../assets/4-2.png')"
                alt=""
              />
              <img
                slot="second"
                style="
                  object-fit: cover;
                  height: 100%;
                  width: 100%;
                  display: block;
                "
                :src="require('../assets/4-1.png')"
                alt=""
              />
            </ImageComparison>
          </div>
          <div class="content">
            <div class="ct_left">
              <div class="title">拖动查看换脸效果</div>
              <!-- <div class="describe">拖动查看换脸效果</div> -->
            </div>
            <div class="ct_right">
              <van-grid :column-num="2" :border="false" direction="horizontal">
                <van-grid-item>
                  <img :src="require('../assets/active.png')" alt="" />
                  <div>合法与安全</div>
                </van-grid-item>
                <van-grid-item>
                  <img :src="require('../assets/active.png')" alt="" />
                  <div>未安装</div>
                </van-grid-item>
                <van-grid-item>
                  <img :src="require('../assets/active.png')" alt="" />
                  <div>高分辨率</div>
                </van-grid-item>
                <van-grid-item>
                  <img :src="require('../assets/active.png')" alt="" />
                  <div>2 个免费学分</div>
                </van-grid-item>
              </van-grid>
            </div>
          </div>
          <div class="driver" style="margin-top: 0.625rem"></div>
        </div>
      </div>
      <div class="quality_box">
        <h3>简单、高质量</h3>
        <div class="quality">
          <!-- <video ref="video_ref" autoplay muted loop>
            <source
              src="https://www.youtube.com/embed/2fGF58wsfTQ?si=TMHFnstIk5c0BqhM"
              type="video/mp4"
            />
            您的浏览器不支持 video 标签。
          </video> -->
          <!-- <img :src="require('../assets/3-1.png')" alt="" />
					<img :src="require('../assets/3-2.png')" alt="" />
					<img :src="require('../assets/3-3.png')" alt="" /> -->
			<iframe width="100%" height="500" src="https://www.youtube.com/embed/2fGF58wsfTQ?si=TMHFnstIk5c0BqhM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
      <!-- 会员 -->
      <div class="price_box">
        <div class="price_box_child">
          <h3>价格合理</h3>
          <div class="describe">您可以用合理的价格获得高质量的效果！</div>
          <div class="price_box_tab">
            <van-tabs
              type="card"
              title-inactive-color="rgba(240, 247, 254, 1)"
              title-active-color="rgba(30, 30, 30, 1)"
			  v-model="activeTab"
            >
              <van-tab title="Monthly" disabled>
				 <div class="item_box">
				   <div class="item basic">
				     <div class="title">基本</div>
				     <div class="price">
				       <span class="num">免费</span>
				     </div>
				     <div class="info">
				       <p>✬ 2 免费信用点数</p>
				       <p>✬ 2 图片交换</p>
				       <p>✬ 定价折扣</p>
				     </div>
				   </div>
				   <div class="item creator">
				     <div class="title">创造者</div>
				     <div class="price">
				       <span class="num">14.99</span>
				       <span class="month">/月</span>
				     </div>
				     <div class="discount">🔥 最受欢迎</div>
				     <div class="info">
				       <p>✬ 每年 960 积分</p>
				       <p>✬ 960 图片交换</p>
				       <p>✬ 1440s 视频交换</p>
				     </div>
				     <div class="get_btn">立即获取</div>
				   </div>
				   <div class="item creator_pro">
				     <div class="title">创作者专业版</div>
				     <div class="price">
				       <span class="unit">$</span>
				       <span class="num">37.49</span>
				       <span class="month">/月</span>
				     </div>
				     <div class="discount">专家专用！</div>
				     <div class="info">
				       <p>✬ 2880 积分/年</p>
				       <p>✬ 2880 图片交换</p>
				       <p>✬ 4320s 视频交换</p>
				     </div>
				     <div class="get_btn">立即获取</div>
				   </div>
				 </div>
				 <div class="hint">自动续订，可随时取消。</div>
              </van-tab>
              <van-tab title="">
				<div class="item_box">
				  <div class="item basic">
				    <div class="title"></div>
				    <div class="price">
				      <span class="num">强大的GPU服务器支持</span>
				    </div>
				    <div class="info">
				      <p>✬ 全球唯一手机换脸APP</p>
				      <p>✬ 支持全球社交APP应用</p>
				      <p>✬ 仅需图片可以快速换脸</p>
				    </div>
				  </div>
				  <div class="item creator">
				    <div class="title">试用版</div>
				    <!-- <div class="price">
				      <span class="num">19.99</span>
				      <span class="month">/月</span>
				    </div> -->
				    <div
				      class="discount"
				      style="background-color: rgba(255, 189, 61, 1)"
				    >
				      100U 单设备 试用功能
				    </div>
				    <div class="info">
				      <p>✬ 支持指定换脸</p>
				      <p>✬ 需要稳定的网络</p>
				      <p>✬ 不需要做任何模型</p>
				      <p>✬ 正式版不限制图片换脸</p>
				    </div>
					<a href="https://t.me/Aiswapme" class="get_btn">立即获取</a>
				    <!-- <div class="get_btn">立即获取</div> -->
				  </div>
				  <div class="item creator_pro">
				    <div class="title">企业版</div>
				    <!-- <div class="price">
				      <span class="unit">$</span>
				      <span class="num">49.99</span>
				      <span class="month">/月</span>
				    </div> -->
				    <div class="discount" style="width: 7.094rem;">提供全部源码和7X24的技术支持</div>
				    <div class="info">
				      <p>✬ 可以随时检查代码后门</p>
				      <p>✬ 可以学习最新的换脸技术</p>
				      <p>✬ 赠送PC端图片换脸源码</p>
					  <p>✬ 提供3年的永久技术更新</p>
				    </div>
					<a href="https://t.me/Aiswapme" class="get_btn">立即获取</a>
				    <!-- <div class="get_btn">立即获取</div> -->
				  </div>
				</div>
				<div class="hint">自动续订，可随时取消。</div>
				
              </van-tab>
            </van-tabs>
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="partner_box">
        <div class="title_box">
          <div class="title">
            <span>支持软件</span>
          </div>
          <div class="ranking">
            <div class="ranking_bg"></div>
            <span class="ranking_text">1st</span>
          </div>
        </div>
        <van-grid :border="false" :column-num="4">
          <van-grid-item>
            <img :src="require('../assets/tiktok.png')" alt="" />
          </van-grid-item>
          <van-grid-item>
            <img :src="require('../assets/logo2.png')" alt="" />
          </van-grid-item>
          <van-grid-item>
            <img :src="require('../assets/logo3.png')" alt="" />
          </van-grid-item>
          <van-grid-item>
            <img :src="require('../assets/logo4.png')" alt="" />
          </van-grid-item>
          <van-grid-item>
            <img :src="require('../assets/logo5.png')" alt="" />
          </van-grid-item>
          <van-grid-item>
            <img :src="require('../assets/logo6.png')" alt="" />
          </van-grid-item>
          <van-grid-item>
            <img :src="require('../assets/logo7.png')" alt="" />
          </van-grid-item>
          <van-grid-item>
            <img :src="require('../assets/logo8.png')" alt="" />
          </van-grid-item>
          <van-grid-item>
            <img :src="require('../assets/logo9.png')" alt="" />
          </van-grid-item>
          <van-grid-item>
            <img :src="require('../assets/logo10.png')" alt="" />
          </van-grid-item>
          <van-grid-item>
            <img :src="require('../assets/logo11.png')" alt="" />
          </van-grid-item>
          <van-grid-item>
            <img :src="require('../assets/logo12.png')" alt="" />
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <footer>
      <div class="footer_box">
        <div class="about_box">
          <div class="item">
            <div class="title">社交媒体</div>
            <div class="media_box">
              <img :src="require('../assets/tuite.png')" alt="" />
              <img :src="require('../assets/facbook.png')" alt="" />
              <img :src="require('../assets/ins.png')" alt="" />
            </div>
          </div>
          <div class="item">
            <div class="title">联系我们</div>
          </div>
          <div class="item">
            <div class="title">术语</div>
            <div class="ct_list">
              <span>服务条款</span>
              <span>隐私政策</span>
              <span>退款政策</span>
            </div>
          </div>
          <div class="item">
            <div class="title">公司名称</div>
            <div class="ct_list">
              <span>博客</span>
            </div>
          </div>
        </div>
        <div class="ft_logo">
          <img :src="require('../assets/logo.png')" alt="" />
        </div>
      </div>
    </footer>
    <!-- 优惠悬浮窗 -->
    <!-- <aside>
			<div class="discount_box">
				<div class="close_box">
					<img :src="require('../assets/cha.png')" alt="" />
				</div>
				<div class="discount">
					<img :src="require('../assets/youhui.png')" alt="" />
				</div>
				<div class="discount_btn">UP TO 50% OFF!</div>
			</div>
		</aside> -->
		<a href="http://cs.aiswap.me/addons/kefu/index/mobile" target="_blank" >
			<img src="../assets/buoy1.png" class="kefu_button"  alt="客服浮标">
		</a>
  </div>
</template>

<script>
// @ is an alias to /src
import ImageComparison from "@/components/ImageComparison.vue";
export default {
  name: "HomeView",
  components: { ImageComparison },
  data() {
    return {
      video_ref: "",
      isLoading: false,
      isVideoPlay: true,
	  activeTab:1
    };
  },
  computed: {
    defaultOption() {
      return {
        isDown: false,
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {},
  mounted() {
    console.log(this.$refs.video_ref, "--video_ref");
  },
  methods: {
    handleMousedown(e) {
      this.isDown = true;
    },
    handleMousemove(e) {
      if (!this.isDown) {
        return;
      }
    },
    handlePlay() {
      this.$refs.video_ref.play();
      this.isVideoPlay = true;
    },
    handlePause() {
      this.$refs.video_ref.pause();
      this.isVideoPlay = false;
    },
  },
};
</script>

<style>
@import "../static/fontIcon/font_lv6wcdg3k3p/iconfont.css";

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(13, 130, 255, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(13, 130, 255, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(13, 130, 255, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(13, 130, 255, 0.4);
        box-shadow: 0 0 0 0 rgba(13, 130, 255, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(13, 130, 255, 0);
        box-shadow: 0 0 0 10px rgba(13, 130, 255, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(13, 130, 255, 0);
        box-shadow: 0 0 0 0 rgba(13, 130, 255, 0);
    }
}
.kefu_button{
	position: fixed;
	top: 65%;
	right: 20px;
	width: 70px;
	height: 70px;
	display: block;
	z-index: 1000;
	animation: pulse 2s infinite;
	border-radius: 50%;
	cursor: pointer;
}

header {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 0.875rem 0;
  z-index: 999;
  background: rgba(234, 244, 255, 0.6);
  backdrop-filter: blur(15px);
  font-size: 0.5rem;
}

.hdBox {
  width: 80vw;
}

.hdBox .navBox {
  text-align: center;
}

.hdBox .userBox .language {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hdBox .userBox .menu {
  display: none;
}

.hdBox .userBox .userLink {
  display: flex;
  align-items: center;
}

.hdBox .userBox .userLink > img {
  width: 1.188rem;
  height: 1.188rem;
}

.hdBox .userBox .userLink .info {
  margin-left: 0.313rem;
}

.hdBox .userBox .userLink .info .user_name {
  font-size: 0.75rem;
}

.hdBox .userBox .userLink .info .user_level {
  font-size: 0.375rem;
  background-color: #ffae33;
  border-radius: 1.25rem;
  width: 2.875rem;
  height: 0.625rem;
  text-align: center;
  line-height: 0.625rem;
  color: #fff;
}

.publicity_box {
  position: relative;
  margin-top: 3.75rem;
}

.publicity_box video {
  width: 37.5rem;
}

.publicity_box .video_icon {
  width: 1.875rem;
  height: 1.875rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -0.938rem;
  margin-top: -0.938rem;
  opacity: 0;
  transition: all 0.5s;
  z-index: 999;
}

.publicity_box:hover .pause {
  opacity: 1;
}

.publicity_box .video_icon:hover {
  cursor: pointer;
}

.faceswap_box {
  /* width: 100vw; */
  background-image: url("../assets/beijing1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: -0.3rem;
  padding: 1.406rem 0;
}

.wefaceswap_box {
  background-image: url("../assets/beijing2.png");
  padding: 3.906rem 0;
}

.faceswap_box .faceswap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faceswap_box .faceswap > img {
  width: 37.5rem;
}

.faceswap_box .faceswap > h3 {
  width: 23.563rem;
}

.wefaceswap_box .img_box {
  width: 37.5rem;
  position: relative;
  overflow: hidden;
}
.img-cp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}
.background-img {
  background-image: url("https://i.loli.net/2020/12/28/1dGpFx3zJ9Pjcme.jpg");
}
.foreground-img {
  background-image: url("https://i.loli.net/2020/12/28/xIZmjtBR5VWoqiz.jpg");
  width: 50%;
}

.wefaceswap_box .img_box > img {
  width: 37.5rem;
}

.wefaceswap_box .img_box .bar_box {
  width: 9.531rem;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
}

.wefaceswap_box .img_box .bar_box .barBg {
  height: 20.313rem;
}

.wefaceswap_box .img_box .bar_box .bar {
  width: 1.563rem;
  margin-left: -0.781rem;
}

.wefaceswap_box .content {
  width: 37.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wefaceswap_box .content .ct_left {
  text-align: left;
}

.wefaceswap_box .content .ct_left .title {
  font-weight: bold;
}

.wefaceswap_box .content .describe {
  font-size: 0.375rem;
}

.wefaceswap_box .content .ct_right {
  width: 45%;
}

.wefaceswap_box
  .content
  .ct_right
  .van-grid
  .van-grid-item
  .van-grid-item__content {
  background-color: transparent;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.5rem;
  padding: 0.5rem 0.25rem;
}

.wefaceswap_box
  .content
  .ct_right
  .van-grid
  .van-grid-item
  .van-grid-item__content
  > img {
  margin-right: 0.313rem;
  width: 0.656rem;
  height: 0.656rem;
}

.wefaceswap_box
  .content
  .ct_right
  .van-grid
  .van-grid-item
  .van-grid-item__content
  > div {
  font-size: 0.5rem;
}

.driver {
  width: 37.5rem;
  height: 0.094rem;
  background: linear-gradient(
    270deg,
    #53e7be 0%,
    #aea3df 14%,
    #eb74ac 26%,
    #a659e3 49%,
    #278de1 80%
  );
  border-radius: 0.625rem;
}

.quality_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 4rem 0;
}

.quality_box h3 {
  width: 37.5rem;
}

.quality_box .quality {
  width: 37.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.quality_box .quality > img {
  width: 10.875rem;
}

.price_box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/beijing4.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* margin-top: -0.3rem; */
  padding: 1.406rem 0;
}

.price_box .price_box_child {
  width: 37.5rem;
}

.price_box .price_box_child .describe {
  font-size: 0.75rem;
  color: rgba(106, 102, 102, 1);
}

.price_box .price_box_child .price_box_tab {
  border-radius: 0.938rem;
  overflow: hidden;
  box-shadow: 0px 4px 20px 0px rgba(152, 169, 255, 0.35);
  margin-top: 2.188rem;
}

.price_box .price_box_child .price_box_tab .van-tabs .van-tabs__wrap {
  height: 1.75rem;
}

.price_box
  .price_box_child
  .price_box_tab
  .van-tabs
  .van-tabs__wrap
  .van-tabs__nav {
  border: none;
  margin: 0;
}

.price_box
  .price_box_child
  .price_box_tab
  .van-tabs
  .van-tabs__wrap
  .van-tabs__nav {
  height: 1.75rem;
  background: rgba(255, 255, 255, 0.6);
}

.price_box
  .price_box_child
  .price_box_tab
  .van-tabs
  .van-tabs__wrap
  .van-tabs__nav
  .van-tab {
  background: linear-gradient(90deg, #c47dff 0%, #357aff 100%);
  border: none;
  font-size: 0.75rem;
  height: 1.75rem;
}

.price_box
  .price_box_child
  .price_box_tab
  .van-tabs
  .van-tabs__wrap
  .van-tabs__nav
  .van-tab:last-child {
  border-radius: 0 0 0 1.75rem;
}

.price_box
  .price_box_child
  .price_box_tab
  .van-tabs
  .van-tabs__wrap
  .van-tabs__nav
  .van-tab:first-child {
  border-radius: 0 0 1.75rem 0;
}

.price_box
  .price_box_child
  .price_box_tab
  .van-tabs
  .van-tabs__wrap
  .van-tabs__nav
  .van-tab--active {
  background: transparent;
}

.price_box .price_box_child .price_box_tab .van-tabs .van-tabs__content {
  background: rgba(255, 255, 255, 0.6);
  padding: 0.75rem 0.938rem;
  backdrop-filter: blur(20px);
}

.price_box .price_box_child .item_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price_box .price_box_child .item_box .item {
  width: 11.375rem;
  height: 15.906rem;
  padding: 1.156rem 1.688rem 2.063rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.price_box .price_box_child .item_box .item .title {
  font-weight: bold;
  font-size: 0.75rem;
  margin-bottom: 0.844rem;
}

.price_box .price_box_child .item_box .item .month {
  font-size: 0.656rem;
}

.price_box .price_box_child .item_box .basic .info {
  margin-top: 1.313rem;
}

.price_box .price_box_child .item_box .creator .info {
  margin-top: 1.313rem;
}

.price_box .price_box_child .item_box .creator_pro .month {
  color: rgba(120, 123, 138, 1);
}

.price_box .price_box_child .item_box .creator_pro .unit {
  color: rgba(68, 123, 255, 1);
  font-weight: bold;
  font-size: 0.75rem;
}

.price_box .price_box_child .item_box .item .info {
  font-size: 0.563rem;
  text-align: left;
}

.price_box .price_box_child .item_box .item .discount {
  width: 5.094rem;
  border-radius: 0.625rem;
  color: #fff;
  font-size: 0.438rem;
  background-color: rgba(121, 207, 255, 1);
  padding: 0.125rem 0;
  margin-top: 0.406rem;
}

.price_box .price_box_child .item_box .basic,
.price_box .price_box_child .item_box .creator_pro {
  background: url(../assets/Rectangle 20.png) no-repeat;
  background-size: 100% 100%;
}

.price_box .price_box_child .item_box .basic .num,
.price_box .price_box_child .item_box .creator_pro .num {
  font-size: 1.125rem;
  color: rgba(68, 123, 255, 1);
  font-weight: bold;
}

.price_box .price_box_child .item_box .creator {
  background: url(../assets/jiagebeijing.png) no-repeat;
  background-size: 100% 100%;
  color: #fff;
}

.price_box .price_box_child .item_box .item .get_btn {
  width: 9.531rem;
  height: 1.688rem;
  line-height: 1.688rem;
  text-align: center;
  background-color: #fff;
  border-radius: 0.375rem;
  font-size: 0.625rem;
  color: rgba(101, 105, 250, 1);
}

.price_box .price_box_child .item_box .creator_pro .get_btn {
  background: linear-gradient(90deg, #c47dff 0%, #357aff 100%);
  color: #fff;
  margin-top: 1.313rem;
}

.price_box .price_box_child .hint {
  margin-top: 0.938rem;
  font-size: 0.438rem;
}

.Reviews_box {
  padding: 1.563rem 0;
  background-image: url("../assets/beijing5.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.Reviews_box .reviews_wallet {
  overflow: hidden;
}

.Reviews_box .reviews_wallet > div > div {
  display: flex;
}

.Reviews_box .reviews_wallet > div > div > div {
  display: flex;
  flex-wrap: wrap;
}

.Reviews_box .reviews_wallet .reviews_wallet_item {
  width: 18.125rem;
  height: 6.656rem;
  display: flex;
  background: linear-gradient(180deg, #f2f9ff 0%, #eaedff 100%);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.625rem;
  margin: 0.375rem 0.75rem;
}

.Reviews_box .reviews_wallet .reviews_wallet_item > img {
  width: 6.656rem;
  height: 6.656rem;
}

.Reviews_box .reviews_wallet .reviews_wallet_item .content_box {
  padding: 1.25rem 1.156rem 0;
}

.Reviews_box .reviews_wallet .reviews_wallet_item .content_box .content {
  font-size: 0.563rem;
}

.Reviews_box .reviews_wallet .reviews_wallet_item .content_box .user_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.563rem;
  margin-top: 0.375rem;
}

.partner_box {
  padding: 1.563rem 0;
  background-image: url("../assets/beijing6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partner_box .title_box {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partner_box .title_box .title {
  width: 10.156rem;
  height: 1.781rem;
  font-size: 0.781rem;
  color: #fff;
  background: url(../assets/partners_title.png) no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.partner_box .title_box .title > span {
  display: block;
  margin-top: 0.188rem;
}

.partner_box .title_box .ranking {
  width: 3.594rem;
  height: 0.688rem;
  color: rgba(61, 123, 255, 1);
  position: relative;
  font-size: 0.531rem;
}

.partner_box .title_box .ranking .ranking_bg {
  width: 3.594rem;
  height: 0.688rem;
  background: linear-gradient(90deg, #c47dff 0%, #357aff 100%);
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0.375rem 0.375rem 0 0;
}

.partner_box .van-grid {
  width: 40rem;
}

.partner_box .van-grid .van-grid-item .van-grid-item__content {
  background-color: transparent;
}

.partner_box .van-grid .van-grid-item .van-grid-item__content img {
  width: 9.125rem;
}

footer {
  width: 100%;
  background: url(../assets/beijing7.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}

.footer_box {
  width: 37.5rem;
  display: flex;
  padding: 1.563rem 0 0.938rem;
  justify-content: space-between;
  font-size: 0.469rem;
  color: #fff;
  text-align: left;
}

.footer_box .about_box {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-right: 3.063rem;
}

.footer_box .about_box .item .title {
  font-size: 0.625rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.footer_box .about_box .item .media_box > img {
  width: 1.188rem;
  height: 1.188rem;
}

.footer_box .about_box .item .media_box > img:not(:first-child) {
  margin-left: 0.438rem;
}

.footer_box .about_box .item .ct_list {
  display: flex;
  flex-direction: column;
}

.footer_box .about_box .item .ct_list > span:not(:first-child) {
  margin-top: 0.375rem;
}

.footer_box .ft_logo {
  width: 6.25rem;
  position: relative;
  padding-left: 2.688rem;
}

.footer_box .ft_logo > img {
  width: 100%;
}

.footer_box .ft_logo::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 0.063rem;
  height: 4.281rem;
  background: radial-gradient(
    30% 50% at 50% 30%,
    #bca1d7 0%,
    rgba(201, 160, 207, 0) 100%
  );
}

aside {
  width: 8.531rem;
  height: 9.125rem;
  border-radius: 0.625rem;
  background: linear-gradient(311deg, #93caff 0%, #eedbff 100%);
  box-shadow: 0px 4px 20px 0px rgba(152, 169, 255, 0.35);
  position: fixed;
  right: 0.75rem;
  top: 18.063rem;
}

.discount_box {
  padding: 0.469rem;
}

.discount_box .close_box {
  display: flex;
  justify-content: flex-end;
}

.discount_box .close_box > img {
  width: 0.563rem;
  height: 0.563rem;
}

.discount_box .discount > img {
  width: 4.313rem;
  height: 3.688rem;
}

.discount_box .discount_btn {
  font-size: 0.625rem;
  background: linear-gradient(90deg, #c47dff 0%, #357aff 100%);
  border-radius: 0.75rem;
  padding: 0.375rem 0;
  color: #fff;
  margin-top: 0.75rem;
}

@media screen and (max-width: 1200px) {
  .hdBox .logoBox > img {
    width: 15.625rem;
  }

  .hdBox .navBox,
  .hdBox .userBox .languageCol .language {
    display: none;
  }

  .hdBox .userBox {
    flex-direction: row-reverse;
  }

  .hdBox .userBox .languageCol {
    width: 20%;
  }

  .hdBox .userBox .languageCol .menu {
    display: flex;
    justify-content: flex-start;
  }

  .banner_box .banner_notice_box .banner_notice > span {
    font-size: 0.725rem;
  }

  .hdBox .userBox .userLink > img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .hdBox .userBox .languageCol .menu .icon-caidan {
    font-size: 2.5rem;
    color: #fff;
  }

  .hdBox .userBox .userLink .info .user_name {
    font-size: 1.5rem;
  }

  .hdBox .userBox .userLink .info .user_level {
    font-size: 0.625rem;
    width: 5.625rem;
    height: 1.25rem;
    text-align: center;
    line-height: 1.5rem;
  }

  .publicity_box {
    margin-top: 4.688rem;
  }

  .wefaceswap_box .content {
    flex-direction: column;
  }

  .wefaceswap_box .content .ct_left {
    text-align: center;
  }

  .wefaceswap_box .content .ct_left .title {
    font-size: 1.2rem;
  }

  .wefaceswap_box .content .ct_left .describe {
    font-size: 1rem;
  }

  .wefaceswap_box .content .ct_right {
    width: 55%;
  }

  .wefaceswap_box
    .content
    .ct_right
    .van-grid
    .van-grid-item
    .van-grid-item__content
    > img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .wefaceswap_box
    .content
    .ct_right
    .van-grid
    .van-grid-item
    .van-grid-item__content
    > div {
    font-size: 1rem;
  }

  .faceswap_box .faceswap > h3 {
    font-size: 1.5rem;
  }

  .quality_box h3 {
    font-size: 1.5rem;
  }

  .quality_box .quality {
    flex-direction: column;
  }

  .price_box .price_box_child > h3 {
    font-size: 1.5rem;
  }

  .price_box .price_box_child .describe {
    font-size: 1rem;
  }

  .quality_box .quality > img:not(:last-child) {
    margin-bottom: 0.626rem;
  }

  .price_box .price_box_child .item_box {
    flex-direction: column;
  }

  .price_box .price_box_child .item_box .item {
    width: 70%;
    height: 28.25rem;
    padding: 2.302rem 2.376rem 4.126rem;
  }

  .price_box .price_box_child .item_box .item .title {
    font-size: 1.5rem;
  }

  .price_box .price_box_child .item_box .basic .num,
  .price_box .price_box_child .item_box .creator_pro .num {
    font-size: 2.25rem;
  }

  .price_box .price_box_child .item_box .item .info {
    font-size: 1.2rem;
  }

  .price_box .price_box_child .item_box .creator .num {
    font-size: 2.25rem;
  }

  .price_box .price_box_child .item_box .item .month {
    font-size: 1.4rem;
  }

  .price_box .price_box_child .item_box .creator .info {
    margin-top: 0;
  }

  .price_box .price_box_child .item_box .item .discount {
    width: 11.8rem;
    padding: 0.25rem 0;
    font-size: 0.876rem;
  }

  .price_box .price_box_child .item_box .item .get_btn {
    width: 20rem;
    height: 2.4rem;
    line-height: 2.4rem;
    font-size: 1.25rem;
  }

  .price_box .price_box_child .price_box_tab .van-tabs .van-tabs__wrap {
    height: 3.5rem;
  }

  .price_box
    .price_box_child
    .price_box_tab
    .van-tabs
    .van-tabs__wrap
    .van-tabs__nav {
    height: 3.5rem;
  }

  .price_box
    .price_box_child
    .price_box_tab
    .van-tabs
    .van-tabs__wrap
    .van-tabs__nav
    .van-tab {
    font-size: 1.25rem;
    height: 3.5rem;
  }

  .price_box
    .price_box_child
    .price_box_tab
    .van-tabs
    .van-tabs__wrap
    .van-tabs__nav
    .van-tab:first-child {
    border-radius: 0 0 3.5rem 0;
  }

  .price_box
    .price_box_child
    .price_box_tab
    .van-tabs
    .van-tabs__wrap
    .van-tabs__nav
    .van-tab:last-child {
    border-radius: 0 0 0 3.5rem;
  }

  .price_box .price_box_child .price_box_tab .van-tabs .van-tabs__content {
    padding: 1.5rem 1.876rem;
  }

  .price_box .price_box_child .hint {
    font-size: 0.876rem;
    margin-top: 1.876rem;
  }

  .Reviews_box > h5 {
    font-size: 1.5rem;
  }

  .Reviews_box .reviews_wallet .reviews_wallet_item {
    height: 18.125rem;
    width: 6.656rem;
    overflow: hidden;
    flex-direction: column;
  }

  .Reviews_box .reviews_wallet .reviews_wallet_item > img {
    border-radius: 0.625rem;
  }

  .Reviews_box .reviews_wallet .reviews_wallet_item .content_box .content {
    font-size: 0.876rem;
    max-height: 6.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    /* 设置你希望显示的行数 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Reviews_box .reviews_wallet .reviews_wallet_item .content_box .user_info {
    flex-direction: column;
    font-size: 0.876rem;
    margin-top: 0.313rem;
  }

  .Reviews_box
    .reviews_wallet
    .reviews_wallet_item
    .content_box
    .user_info
    .user_name {
    margin-top: 0.375rem;
  }

  .partner_box .title_box .title {
    font-size: 1.5rem;
    width: 20.312rem;
    height: 3.562rem;
  }

  .partner_box .title_box .title > span {
    margin-top: 0.376rem;
  }

  .partner_box .title_box .ranking {
    width: 7.188rem;
    height: 1.376rem;
    font-size: 1.062rem;
  }

  .partner_box .title_box .ranking .ranking_bg {
    width: 7.188rem;
    height: 1.376rem;
    border-radius: 0.75rem 0.75rem 0 0;
  }

  .footer_box {
    align-items: center;
    flex-direction: column-reverse;
  }

  .footer_box .about_box {
    font-size: 1.5rem;
    margin-top: 0.75rem;
    margin-right: 0;
  }

  .footer_box .about_box .item:not(:first-child) {
    margin-left: 0.375rem;
  }

  .footer_box .about_box .item .title {
    font-size: 1.6rem;
  }

  .footer_box .about_box .item .media_box > img {
    width: 2rem;
    height: 2rem;
  }

  .footer_box .about_box .item .ct_list {
    margin-top: 2.5rem;
  }

  .footer_box .ft_logo {
    width: 22.25rem;
    padding-left: 0;
  }

  .footer_box .ft_logo::before {
    display: none;
  }

  /* .partner_box .van-grid {
			width: 33.2rem;
		}
		.discount_box .close_box>img{
			width: 1.125rem;
			height: 1.125rem;
		} */
  /* .discount_box .discount>img{
			width: 4.313rem;
			height: 3.688rem;
		} */
}
</style>
